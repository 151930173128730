export const items = [
  {
    titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
    bgImg: '/img/portfolio/21.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
    categoryKey: 'portfolio.LENINGRADSKIY_PROSPECT_SALES_OFFICE',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/21_21.webp'
  },
  // {
  //   titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
  //   bgImg: '/img/portfolio/22.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
  //   categoryKey: 'portfolio.LENINGRADSKIY_PROSPECT_SALES_OFFICE',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/22_22.webp'
  // },
  // {
  //   titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
  //   bgImg: '/img/portfolio/23.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.LENINGRADSKIY_PROSPECT',
  //   categoryKey: 'portfolio.LENINGRADSKIY_PROSPECT_SALES_OFFICE',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/23_23.webp'
  // },
  {
    titleKey: 'portfolio.SAVELOVSKIY_CITY_OFFICE',
    bgImg: '/img/portfolio/17.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.SAVELOVSKIY_CITY_OFFICE',
    categoryKey: 'portfolio.SAVELOVSKIY_CITY_OFFICE_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/17_17.webp'
  },
  {
    titleKey: 'portfolio.TORPEDO_APARTMENT_SALES_OFFICE',
    bgImg: '/img/portfolio/19.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.TORPEDO_APARTMENT_SALES_OFFICE',
    categoryKey: 'portfolio.TORPEDO_APARTMENT_SALES_OFFICE_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/19_19.webp'
  },
  // {
  //   titleKey: 'portfolio.TPU_SKAZKA',
  //   bgImg: '/img/portfolio/2.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.TPU_SKAZKA',
  //   categoryKey: 'portfolio.TPU_SKAZKA_desc',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/2_2.webp'
  // },
  {
    titleKey: 'portfolio.TRC_FORUM',
    bgImg: '/img/portfolio/3.webp',
    subtitleKey: 'cities.ulan_ude'
  },
  {
    titleKey: 'portfolio.TRC_FORUM',
    categoryKey: 'portfolio.TRC_FORUM_desc',
    subtitleKey: 'cities.ulan_ude',
    img: '/img/portfolio/3_3.webp'
  },
  {
    titleKey: 'portfolio.DISCOUNT_CENTER_ORDZHONIKIDZE_11',
    bgImg: '/img/portfolio/4.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.DISCOUNT_CENTER_ORDZHONIKIDZE_11',
    categoryKey: 'portfolio.DISCOUNT_CENTER_ORDZHONIKIDZE_11_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/4_4.webp'
  },
  // {
  //   titleKey: 'portfolio.TRC_YUZHNY',
  //   bgImg: '/img/portfolio/5.webp',
  //   subtitleKey: 'cities.tyumen'
  // },
  // {
  //   titleKey: 'portfolio.TRC_YUZHNY',
  //   categoryKey: 'portfolio.TRC_YUZHNY_desc',
  //   subtitleKey: 'cities.tyumen',
  //   img: '/img/portfolio/5_5.webp'
  // },
  // {
  //   titleKey: 'portfolio.TRC_POBEDA_PLAZA',
  //   bgImg: '/img/portfolio/6.webp',
  //   subtitleKey: 'cities.saratov'
  // },
  // {
  //   titleKey: 'portfolio.TRC_POBEDA_PLAZA',
  //   categoryKey: 'portfolio.TRC_POBEDA_PLAZA_desc',
  //   subtitleKey: 'cities.saratov',
  //   img: '/img/portfolio/6_6.webp'
  // },
  // {
  //   titleKey: 'portfolio.TVERSKOY_PASSAGE',
  //   bgImg: '/img/portfolio/7.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.TVERSKOY_PASSAGE',
  //   categoryKey: 'portfolio.TVERSKOY_PASSAGE_desc',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/7_7.webp'
  // },
  // {
  //   titleKey: 'portfolio.PAVELETSKAYA',
  //   bgImg: '/img/portfolio/1.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.PAVELETSKAYA',
  //   categoryKey: 'portfolio.PAVELETSKAYA_desc',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/1_1.webp'
  // },
  {
    titleKey: 'portfolio.NOVY_ARBAT',
    bgImg: '/img/portfolio/8.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.NOVY_ARBAT',
    categoryKey: 'portfolio.NOVY_ARBAT_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/8_8.webp'
  },
  {
    titleKey: 'portfolio.AMINEVSKOYE_SHOSSE',
    bgImg: '/img/portfolio/9.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.AMINEVSKOYE_SHOSSE',
    categoryKey: 'portfolio.AMINEVSKOYE_SHOSSE_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/9_9.webp'
  },
  // {
  //   titleKey: 'portfolio.TRC_GALERIA',
  //   bgImg: '/img/portfolio/10.webp',
  //   subtitleKey: 'cities.saint_petersburg'
  // },
  // {
  //   titleKey: 'portfolio.TRC_GALERIA',
  //   categoryKey: 'portfolio.TRC_GALERIA_desc',
  //   subtitleKey: 'cities.saint_petersburg',
  //   img: '/img/portfolio/10_10.webp'
  // },
  {
    titleKey: 'portfolio.YAROSLAVSKOYE_SHOSSE',
    bgImg: '/img/portfolio/11.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.YAROSLAVSKOYE_SHOSSE',
    categoryKey: 'portfolio.YAROSLAVSKOYE_SHOSSE_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/11_11.webp'
  },
  {
    titleKey: 'portfolio.TRC_VOROSHILOVSKIY',
    bgImg: '/img/portfolio/12.webp',
    subtitleKey: 'cities.volgograd'
  },
  {
    titleKey: 'portfolio.TRC_VOROSHILOVSKIY',
    categoryKey: 'portfolio.TRC_VOROSHILOVSKIY_desc',
    subtitleKey: 'cities.volgograd',
    img: '/img/portfolio/12_12.webp'
  },
  {
    titleKey: 'portfolio.TRC_VOROSHILOVSKIY_2',
    bgImg: '/img/portfolio/14.webp',
    subtitleKey: 'cities.volgograd'
  },
  {
    titleKey: 'portfolio.TRC_VOROSHILOVSKIY_2',
    categoryKey: 'portfolio.TRC_VOROSHILOVSKIY_2_desc',
    subtitleKey: 'cities.volgograd',
    img: '/img/portfolio/14_14.webp'
  },
  {
    titleKey: 'portfolio.TRC_POBEDA_PLAZA_2',
    bgImg: '/img/portfolio/15.webp',
    subtitleKey: 'cities.saratov'
  },
  {
    titleKey: 'portfolio.TRC_POBEDA_PLAZA_2',
    categoryKey: 'portfolio.TRC_POBEDA_PLAZA_2_desc',
    subtitleKey: 'cities.saratov',
    img: '/img/portfolio/15_15.webp'
  },
  {
    titleKey: 'portfolio.URAL_GRANIT_HEADQUARTERS',
    bgImg: '/img/portfolio/16.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.URAL_GRANIT_HEADQUARTERS',
    categoryKey: 'portfolio.URAL_GRANIT_HEADQUARTERS_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/16_16.webp'
  },
  {
    titleKey: 'portfolio.PRIME_PARK_RESIDENTIAL_QUARTER',
    bgImg: '/img/portfolio/18.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.PRIME_PARK_RESIDENTIAL_QUARTER',
    categoryKey: 'portfolio.PRIME_PARK_RESIDENTIAL_QUARTER_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/18_18.webp'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    bgImg: '/img/portfolio/24.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    categoryKey: 'portfolio.BC_ICITY_MR_GROUP_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/24_24.webp'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    bgImg: '/img/portfolio/25.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    categoryKey: 'portfolio.BC_ICITY_MR_GROUP_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/25_25.webp'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    bgImg: '/img/portfolio/26.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    categoryKey: 'portfolio.BC_ICITY_MR_GROUP_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/26_26.webp'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    bgImg: '/img/portfolio/27.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    categoryKey: 'portfolio.BC_ICITY_MR_GROUP_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/27_27.webp'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    bgImg: '/img/portfolio/28.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.BC_ICITY_MR_GROUP',
    categoryKey: 'portfolio.BC_ICITY_MR_GROUP_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/28_28.webp'
  },
  {
    titleKey: 'portfolio.OFFICES_MOSCOW_CITY',
    bgImg: '/img/portfolio/29.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.OFFICES_MOSCOW_CITY',
    categoryKey: 'portfolio.OFFICES_MOSCOW_CITY_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/29_29.webp'
  },
  {
    titleKey: 'portfolio.OFFICES_MOSCOW_CITY',
    bgImg: '/img/portfolio/30.webp',
    subtitleKey: 'cities.moscow'
  },
  {
    titleKey: 'portfolio.OFFICES_MOSCOW_CITY',
    categoryKey: 'portfolio.OFFICES_MOSCOW_CITY_desc',
    subtitleKey: 'cities.moscow',
    img: '/img/portfolio/30_30.webp'
  },
  // {
  //   titleKey: 'portfolio.RUBLEVO_PARK',
  //   bgImg: '/img/portfolio/20.webp',
  //   subtitleKey: 'cities.moscow'
  // },
  // {
  //   titleKey: 'portfolio.RUBLEVO_PARK',
  //   categoryKey: 'portfolio.RUBLEVO_PARK_desc',
  //   subtitleKey: 'cities.moscow',
  //   img: '/img/portfolio/20_20.webp'
  // }
];